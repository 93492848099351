<template>
  <div>
    <fieldset class="mb-6">
      <legend>Accès habitation</legend>
      <base-field horizontal label="Type*" name="property_type">
        <b-radio-button
          v-for="(label, value) in propertyTypes"
          :key="value"
          v-model="form.property_type"
          :native-value="value"
          type="is-success is-light is-outlined"
        >
          {{ label }}
        </b-radio-button>
      </base-field>
      <div v-show="form.property_type === 'APARTMENT'" class="mb-3">
        <base-field horizontal label="Code d'accès" name="property_access_code">
          <b-input v-model="form.property_access_code" />
        </base-field>
        <base-field horizontal label="Etage*" name="property_floor">
          <b-input v-model="form.property_floor" />
        </base-field>
        <base-field horizontal label="Numéro d'appart.*" name="property_number">
          <b-input v-model="form.property_number" name="property_number" />
        </base-field>
      </div>
      <base-field horizontal label="Autres précisions">
        <b-input
          v-model="form.property_details"
          name="property_details"
          type="textarea"
          placeholder="Présence, d'une boite à clefs, couleur du portail, code d'entrée..."
        />
      </base-field>
    </fieldset>

    <div>
      <fieldset v-for="(contact, i) in form.contacts" :key="i" class="mb-5">
        <legend>
          <span>Contact n°{{ i + 1 }}</span>
          <button
            v-if="form.contacts.length > 2"
            type="button"
            class="button is-primary ml-auto"
            @click="deleteContact(i)"
          >
            Supprimer
          </button>
        </legend>
        <subscription-contact-form
          v-model="form.contacts[i]"
          :prefix="`contacts.${i}.`"
        />
      </fieldset>
      <div style="display: flex">
        <button
          type="button"
          v-if="form.contacts.length < 4"
          class="button is-primary ml-auto"
          @click="addContact()"
        >
          Ajouter un contact
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SubscriptionContactForm from './subscription-contact-form.vue'

export default {
  components: {
    SubscriptionContactForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      propertyTypes: (state) => state.enums.propertyTypes,
    }),
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    addContact() {
      this.form.contacts.push({
        civility: null,
        last_name: null,
        first_name: null,
        phone: null,
        travel_time: null,
        has_home_keys: false,
        is_parent: false,
      })
    },
    deleteContact(index) {
      this.form.contacts.splice(index, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
fieldset {
  margin-bottom: 1.5rem;
}
legend {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/deep/ .textarea {
  &::placeholder {
    color: $grey-light;
  }
}
</style>
